.langMenu {
    position: fixed;
    margin-left: 20px;
    margin-top: 20px;
    background-color: var(--elements);
    width: 45px;
    border: 1px solid var(--elements-hover);
    height: 45px;
    border-radius: 50%;
    z-index: 99;
    transition: all .3s ease-in-out;
    cursor: pointer;

    .selectedLang {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }
    }

    .selectLangMenu {
        display: none;
        background-color: var(--elements);
        justify-content: center;
        align-items: center;
        width: 125px;
        border-radius: 0px 12px 12px 12px;
        border: 1px solid var(--elements-hover);
        margin-left: 25px;
        margin-top: 7.5px;

        ul {
            display: flex;
            width: 100%;
            padding: 0px;
            margin: 0px;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 5px;
                width: 100%;
                transition: all .3s ease-in-out;

                img {
                    width: 25px;
                    height: 25px;
                    margin-left: 5px;
                    border-radius: 50%;
                }

                p {
                    margin-left: 5px;
                }
            }

            li:first-child {
                border-radius: 0px 12px 0px 0px;
            }

            li:last-child {
                border-radius: 0px 0px 12px 12px;
            }

            li:hover {
                background-color: var(--elements-hover);
            }
        }
    }

    .selectLangMenu.open {
        display: block;
    }

}