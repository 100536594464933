

.contact_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--font);
    margin-top: 50px;
    margin-bottom: 200px;

    .contact_card::after {
        content: "";
        position: absolute;
        background-image: conic-gradient(from var(--angle), transparent 70%, var(--primary), var(--second));
        border-radius: inherit;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 3px;
        animation: 3s spin linear infinite;
    }

    .contact_card::before {
        content: "";
        position: absolute;
        background-image: conic-gradient(from var(--angle), transparent 70%, var(--primary), var(--second));
        border-radius: inherit;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(10px);
        padding: 2px;
        animation: 3s spin linear infinite;
    }
    

    .contact_card {
        width: 600px;
        height: 400px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        background-color: var(--elements);
        border: 1px solid var(--elements-hover);
        box-shadow: 0px 0px 10px #00000056;
        position: relative;

        
    
        .successSent {
            display: flex;
            width: 80%;
            text-align: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            p {
                margin-top: -5px;
                font-size: 18px;
            }
        }

        form {
            width: 80%;

            input, textarea {
                margin-top: 25px;
                font-family: monospace;
                width: 100%;
                height: 25px;
                border-radius: 12px;
                outline: none;
                border: 1px solid var(--elements-hover);
                padding: 10px;
                color: var(--font);
                background-color: var(--elements);
                transition: all .2s ease-in-out;
            }

            .content {
                height: 100px;
                resize: none;
            }

            
            button {
                position: relative;
                cursor: pointer;
                margin-top: 25px;
                font-family: monospace;
                text-align: center;
                width: 100%;
                height: 50px;
                border-radius: 12px;
                outline: none;
                border: 2px solid var(--primary);
                padding: 10px;
                color: var(--font-darker);
                background-color: var(--primary);
            }
            
            button:hover {
                color: var(--font);
                transform: translateY(-5px);
                background-color: transparent;
                box-shadow: -10px 10px 0px #00000044;
            }

            input:focus, input:hover, textarea:focus, textarea:hover {
                border: 1px solid var(--primary);
            }
        }
    }
}
