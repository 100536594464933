:root {
    --card-cover: #00000056;
    --card-cover-hover: #00000000;

}

.container_cards {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--font);
}

.CardContainer {
    display: grid;
    grid-template-columns: repeat(2, 600px);
    grid-template-rows: repeat(1, 410px);
    grid-column-gap: 45px;
    grid-row-gap: 35px;

    .card::after {
        content: "";
        position: absolute;
        background-image: conic-gradient(from var(--angle), transparent 70%, var(--primary), var(--second));
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: inherit;
        z-index: -1;
        padding: 3px;
        animation: 3s spin linear infinite;
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }
    
    .card::before {
        content: "";
        filter: blur(10px);
        position: absolute;
        background-image: conic-gradient(from var(--angle), transparent 70%, var(--primary), var(--second));
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: inherit;
        z-index: -1;
        padding: 3px;
        animation: 3s spin linear infinite;
        opacity: 0;
        transition: opacity .2s ease-in-out;
    }

    .card:hover::after, .card:hover::before {
        opacity: 1;
    }

    .card {
        position: relative;
        border: 1px solid var(--elements-hover);
        width: 600px;
        height: 410px;
        border-radius: 12px;
        justify-content: center;
        justify-items: end;
        transition: all .2s ease-in-out;
        color: var(--font);

        .image {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-size: contain;
            background-position: center;
        }

        .image .cover {
            width: 600px;
            height: 310px;
            background-color: var(--card-cover);
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            transition: all .5s ease-in-out;
        }

        .info {
            position: absolute;
            bottom: 0;
            width: 600px;
            height: 110px;
            background-color: var(--elements);
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }

        .info .project_name {
            font-weight: bold;
            font-size: 20px;
            color: var(--font-darker);
            padding-top: 5px;
            padding-left: 10px;
        }

        .info .project_description {
            padding-top: 10px;
            padding-left: 10px;
            color: var(--font-darker);
        }

        .info .language {
            position: absolute;
            margin-left: 10px;
            margin-bottom: 10px;
            bottom: 0;
            border-radius: 12px;
            padding: 4px;
        }
    }

    .card:hover {
        translate: 5px -10px;
        box-shadow: -15px 15px 0px #00000044;

        .image .cover {
            background-color: var(--card-cover-hover);
        }

        .info .project_name {
            color: var(--font)
        }
    }
}