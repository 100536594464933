
.spotify_container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--font);
    margin-bottom: 15px;
    
    a {
        cursor: pointer;
        text-decoration: none;
        
        .spotify_card::after {
            content: "";
            position: absolute;
            background-image: conic-gradient(from var(--angle), transparent 70%, var(--primary), var(--second));
            top: 50%;
            left: 50%;
            border-radius: inherit;
            z-index: -1;
            padding: 3px;
            width: 100%;
            height: 100%;
            animation: 3s spin linear infinite;
            transform: translate(-50%, -50%);
        }
        
        .spotify_card::before {
            content: "";
            position: absolute;
            background-image: conic-gradient(from var(--angle), transparent 70%, var(--primary), var(--second));
            top: 50%;
            left: 50%;
            border-radius: inherit;
            z-index: -1;
            padding: 2px;
            filter: blur(15px);
            width: 100%;
            height: 100%;
            animation: 3s spin linear infinite;
            transform: translate(-50%, -50%);
        }

        .spotify_card {
            display: flex;
            position: relative;
            width: 500px;
            height: 125px;
            border-radius: 12px;
            background-color: var(--elements);
            border: 1px solid var(--elements-hover);
            align-items: center;
            box-shadow: 0px 0px 10px #00000056;
            
            img {
                width: 100px;
                height: 100px;
                border-radius: 12px;
                margin-left: 15px;
                box-shadow: 0px 0px 15px #00000056;
            }

            .song_information {
                display: block;

                h2 {
                    margin-top: 35px;
                    white-space:nowrap;
                    width: 350px;
                    height: 25px;
                    margin-left: 15px;
                    color: var(--font);
                    overflow: hidden;
                    text-overflow: ellipsis;
              
                }

                p {
                    margin-top: 25px;
                    position: relative;
                    margin-left: 15px;
                    transform: translate(0, -30px);
                    color: var(--font-darker);
                }
            }
        }
    }
}
