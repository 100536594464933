@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

#wrapper {
    background-color: var(--bg);
    padding-top: 100px;
}

.container {

    .readme_container {
        z-index: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--bg);
    }
    
    .text {
        box-sizing: border-box;
        align-self: center;
        display: block;
    }

    .name {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        
        h1 {
            background: linear-gradient(90deg, var(--primary), var(--second));
            background-clip: text;
            color: transparent;
            display: block;
            font-family: 'Fira Code', monospace;
            font-size: 1.9em;
            font-weight: 700;

            span {
                color: var(--second);
            }
        }
    }

    .info {
        display: block;
        padding-top: 30px;
    }

    .readMe {
        display: flex;
        flex-direction: column;
        font-family: 'Fira Code', monospace;
        font-weight: 500;
        font-size: 1.3em;
        color: var(--font);
        min-width: 80%;
        width: 80%;
        flex: 1 1 0%;

        .author {
            background: linear-gradient(90deg, var(--primary), var(--second));
            background-clip: text;
            color: transparent;
        }

        a {
            text-decoration: underline;
            color: var(--font);
            transition: all .3s ease-in-out;
        }

        a:hover {
            color: var(--primary);
        }
    }

    .socials {
        display: flex;
        padding: 0;

        li {
            list-style: none;
            padding-left: 0px;
            padding-right: 10px;
            opacity: 1;
        }

        svg:hover {
            transform: translateY(-2px);
            * {
                -webkit-filter: drop-shadow( 0px 0px 2px var(--primary));
                filter: drop-shadow( 0px 0px 2px var(--primary));
                color: var(--primary);
            }
        }
        
        * {
            font-size: 40px;
            color: var(--font);
            transition: all .1s linear 0s;
        }
    }

    .image {

        svg {
            pointer-events: none;
            position: relative;
            display: flex;

            text-align: center;
            justify-content: center;
            border-radius: 50%;
            transform: translate(250px, 330px);
            border: 15px solid var(--bg);
            bottom: 0;
            width: 50px;
            height: 50px;
            background-color: var(--bg);
        }

        img {
            border-radius: 50%;
            box-shadow: 0px 0px 25px #0000007a;
            cursor: pointer;
            pointer-events: none;
            width: 350px;
            max-height: 470px;
        }

    }
        

}