// =============================================== COLORS =============================================== // 

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

:root {
  --primary: #4efac1;
  --second: rgb(0, 183, 255);
  --bg: #070707;
  --elements: #0a0a0a;
  --elements-hover: #141414;
  --font: #ebdbb2;
  --font-darker: #5e5e5e;
  --twitter: #1c97ea;
  --github: #4d6589;
  --youtube: #ff0000;
  --discord: #5865f2;
  --instagram: rgb(153, 37, 248);
  --tryhackme: #801515;
  --snow-length: 300vh;
}

[data-theme="light"] {
  --bg: #ffffff;
  --elements: #f4f4f5;
  --elements-hover: #e9e9e9;
  --font: #0f0f0f;
  --font-darker: #3a3a3a;
}


// =============================================== SNOW =============================================== // 

.snowflake {
  display: inline-block;
  position: relative;
  pointer-events: none;
	color: #FFFAFA;
	opacity: 0;
	margin: 0;
	padding: 0;
	animation: fall 24s linear infinite;
  z-index: 1;
}

@keyframes fall {
  0% {
    opacity: 0;
	}
	3% {
    opacity: 0.6;
	}
  
	90% {
    opacity: 0.6;
	}
	100% {
    transform: translate(0, var(--snow-length));
		opacity: 0;
	}
}

// =============================================== MAIN =============================================== //

* {
  transition: all 150ms ease-in-out 0s;
}

body {
  margin: 0;
  padding: 0;
  font-family: monospace;
  overflow-x: hidden;
  background-color: var(--bg);
  color: var(--font);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

// =============================================== FOOTER =============================================== //

.footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  min-width: fit-content;
  padding-top: 40px;
  width: 100%;
  z-index: 2;
  max-height: max-content;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-bottom: 40px;
  background-color: var(--bg);
  color: var(--font);
  
  .left {
    left: 0px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: row;
    display: flex;
    font-size: 15px;
    
    .time {
      text-shadow: 0px 0px 5px var(--primary);
    }
  }
  
  a {
    text-decoration: none;
    color: var(--font);
  }
  
  a:hover {
    color: var(--primary);
    text-decoration: underline;
  }
  
  .theme {
    color: var(--font);
    cursor: pointer;
  }
  
  .theme:hover {
    color: var(--primary);
  }
  
  .right { 
    right: 0px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: row;
    display: flex;
    font-size: 15px;
  }
}

// =============================================== SCROLLBAR =============================================== //
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--elements);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

// =============================================== RESPONSIVE =============================================== //

@media (max-width: 480px) {
  :root {
    --snow-length: 440vh;
  }
  
  body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    flex-wrap: wrap;
  }
  #wrapper {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 480px;
    margin: 0;
  }
  .container {
    width: 100%;
    max-width: 480px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 15px;
    border: none;

    .text {
      max-width: 480px;
      min-width: none;
    }

    .info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .image {
      display: none;
    }
  }
  .container_cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 5px;
    max-width: fit-content;
  }
  .CardContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 15px;

    .card {
      width: 350px
    }

    .card .image {
      width: 350px;
    }

    .card .image .cover {
      width: 350px;
    }

    .card .info {
      width: 350px;
      height: 150px;
    }
  }

  .contact_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -15px;
    
    .contact_card {
      width: 350px;
    }
  }

  .spotify_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -15px;

    a .spotify_card {
      width: 350px;
      margin-left: -15px;

      .song_information h2 {
        width: 200px;
      }
    }
  }

  .footer {
    flex-direction: column;
    width: max-content;
  }

  .snowflake {
    width: max-content;
  }
}


@keyframes spin {
  from {
      --angle: 0deg;
  }
  to {
      --angle: 360deg;
  }
}